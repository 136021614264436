<template>
  <div class="row" style="background:#ededee; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2); padding-bottom: 25px;">
    <div class="col-md-12 col-sm-12 mt-1">
      <div class="row mt-1">
        <!-- <div class="col-md-12"> -->
            <!-- <span class="text-left" :class="$style.title">PLATFORMS</span> -->
            <!-- <span :class="$style.notificationNumber" v-if="lmsHomeworkCount !== 0">{{lmsHomeworkCount}}</span> -->
        <!-- </div> -->
      </div>
      <!-- <div v-if="roleType === 'student'" class="row mt-2">
          <div class="col-md-6 mt-1" v-if="Number(this.grade) >= 4 && Number(this.grade) <= 7">
            <a :href="`https://www.hexagonia.com.tr${querystingtext}`">
                <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnHexagonia]"></div>
                <div :class="$style.txtPlatform">HEXAGONIA</div>
              </div>
            </a>
          </div>

          <div class="col-md-6 mt-1" v-if="(Number(this.grade) >= 6 && Number(this.grade) <= 7)">
              <a @click="nom()" target="_blank">
                <div :class="$style.divPlatform">
                  <div :class="[$style.divPlatformBtnModal, $style.divPlatformBtnNewsomatic]"></div>
                  <div :class="$style.txtPlatform">NEWS-O-MATIC</div>
                </div>
              </a>
          </div>

      </div>
      <div v-else-if="roleType === 'teacher'" class="row mt-2">
       
      </div> -->
    </div>
  <!-- <div v-else-if="roleType === 'teacher' && userDetails.lang === 'en'" class="row mt-1">
    <div class="col-md-12 mt-1" v-if="levelName === 'İlkokul'">
      <a href="https://classroom.google.com" target="_blank">
        <div :class="$style.divPlatform">
          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnGoogle]"></div>
          <div :class="$style.txtPlatform">CLASSROOM</div>
        </div>
      </a>
    </div>
    <div class="col-md-6 mt-1" v-if="levelName === 'Ortaokul'">
      <a :href="`http://admin.agkcity.com/login${querystingtext}`" target="_blank">
        <div :class="$style.divPlatform">
          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnAgk]"></div>
          <div :class="$style.txtPlatform">AGK CITY</div>
        </div>
      </a>
    </div>
    <div class="col-md-6 mt-1" v-if="levelName === 'Ortaokul'">
      <a href="https://classroom.google.com" target="_blank">
        <div :class="$style.divPlatform">
          <div :class="[$style.divPlatformBtn, $style.divPlatformBtnGoogle]"></div>
          <div :class="$style.txtPlatform">CLASSROOM</div>
        </div>
      </a>
    </div>
    <div class="col-md-12 mt-1" v-if="levelName === 'Lise'">
      <a :href="`http://gundogdu.ueslms.com/login${querystingtext}`" target="_blank">
        <div :class="$style.divPlatform">
          <div :class="[$style.divPlatformBtnTeachersResources, $style.divPlatformTeacherContents]"></div>
        </div>
      </a>
    </div>
  </div> -->
  </div>
</template>

<script>
import jwt from 'jsonwebtoken';
import { mapState } from 'vuex';
import services from '@/services/user';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';

export default {
  name: 'platforms',
  data() {
    return {
      grade: '',
      level: '',
      roleType: '',
      querystingtext: '',
      kwquerystingtext: '',
      hexaquerystingtext: '',
      zoom: '',
      checkTime: true,
    };
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  beforeMount() {
    this.setData();
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {

    async timeStamp() {
      const res = await services.getTimeStamp();
      if (res.data.is_weekend) return false;
      // eslint-disable-next-line
      return (res.data.data.split(' ')[1] > '08:00:00') && (res.data.data.split(' ')[1] < '17:00:00') ? true : false;
    },
    async getZoomTokenStudent() {
      // const checkTime = await this.timeStamp();
      // if (checkTime === false) return;
      const time = Date.now();
      const parsedTime = String(time).substring(0, 10);
      const fullName = `${this.userDetails.first_name} ${this.userDetails.last_name}`;
      const payload = {
        context: {
          user: {
            avatar: '',
            name: fullName,
            email: this.userDetails.email,
            affiliation: 'member',
          },
          features: {
            'screen-sharing': false,
          },
        },
        aud: 'myeducams',
        iss: 'myeducams',
        sub: 'myeducams.com',
        room: `${this.zoom[0].id}-${this.zoom[0].name}`,
        moderator: false,
        exp: Number(parsedTime) + 60,
      };
      const token = jwt.sign(payload, 'mysecret');
      window.open(`https://myeducams.com/${this.zoom[0].id}-${this.zoom[0].name}?jwt=${token}`, '_blank');
    },
    async getZoomTokenTeacher(zoomClass) {
      // const checkTime = await this.timeStamp();
      // if (checkTime === false) return;
      const time = Date.now();
      const parsedTime = String(time).substring(0, 10);
      const fullName = `${this.userDetails.first_name} ${this.userDetails.last_name}`;
      const payload = {
        context: {
          user: {
            avatar: '',
            name: fullName,
            email: this.userDetails.email,
            affiliation: 'owner',
          },
          features: {
            'screen-sharing': true,
          },
        },
        aud: 'myeducams',
        iss: 'myeducams',
        sub: 'myeducams.com',
        room: zoomClass,
        moderator: true,
        exp: Number(parsedTime) + 60,
      };
      const token = jwt.sign(payload, 'mysecret');
      window.open(`https://myeducams.com/${zoomClass}?jwt=${token}`, '_blank');
    },
    async setData() {
      console.log(this.userDetails)
      if (this.userDetails) {      
        this.level = this.userDetails.organization.find(x => x.type === 'level').name;
        this.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        this.zoom = this.userDetails.organization.filter(x => x.type === 'grade');
        this.roleType = this.userDetails.role_type;
        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        if (this.userDetails.lang === 'en') {
          this.hexaquerystingtext = `?username=${btoa('steve.jobs')}&password=${btoa('090807')}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }
        this.checkTime = await this.timeStamp();
      }
      this.kwquerystingtext = `?username=${btoa('uesnlkw_teacher')}&password=${btoa('123456')}`;
    },

    nom() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "Pbt6DND9k5tu7Tdj284-1szLNPEXwcVznFMN4G@UdQtZ7.GEU7!BPy";
        const userData = {}
        if (this.userDetails) {
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.school_name =  this.userDetails.organization.find(x => x.type === 'main').name;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;
          userData.class = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          userData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
        }
        //  console.log(userData)

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/nom/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },

  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
